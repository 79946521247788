export const colors = {
  white: '#ffffff',
  'mine-shaft': '#333333',
  'dove-gray': '#666666',
  emperor: '#555555',
  'text-article': '#3a3a3a',
  silver: '#c4c4c4',
  'athens-gray': '#f0f0f5',
  cyan: '#00f2ee',
  'dodger-blue': '#4facfe',
  aqua: '#00d8fe',
  gray: '#e1e4e8',
  link: '#0366d6',
  'shuttle-gray': '#586069',
  'pale-sky': '#6a737d',
  'spun-pearl': '#a8a8b3',
};
